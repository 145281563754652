<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <ProductType @on-select-type="onSelectType"/>
        <!--e: Type-->

        <!--s: check-->
        <div class="product-check">

            <!--s: Product Image-->
            <div class="check-image" :style="'background-image: url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Ftfscom%2Fi4%2F88102435%2FTB2Lc3gbfBNTKJjy1zdXXaScpXa_%21%2188102435.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638072696&t=47b9d4b32fdfb7f88f42551ccec50b38)'"></div>
            <!--e: Product Image-->

            <!--s: Product information-->
            <div class="check-information">
                <div class="check-top">
                    <div class="check-label">
                        <i class="icon state"></i>
                        <p class="text">Featured</p>
                    </div>
                    <div class="check-label">
                        <i class="icon draft"></i>
                        <p class="text">Runway</p>
                    </div>
                    <div class="check-label">
                        <span class="label green">New in</span>
                    </div>
                    <div class="check-label">
                        <span class="label red">Sale</span>
                    </div>
                </div>
                <h1 class="check-title">Takahiromayiyashita The Soloist<br/> x John Galliano</h1>
                <h2 class="check-describe">Women's Natural The Kensington<br/> Cotton-gabardine Trench Coat</h2>
                <div class="check-info" :style="!detailsVisible?'-webkit-line-clamp: 3;':''">An iconic heritage coat, the Burberry trench was originally designed for the mili- tary, and the innovative weatherproof cotton-gabardine was invented by Thomas Burberry in 1879. Well over 100 years later, the original has evolved</div>
                <div class="check-info-btn"><a href="javascript:" @click="onDetailsMore">View more details<i class="down"></i></a></div>
                <div class="check-size">

                </div>
            </div>
            <!--e: Product information-->

        </div>
        <!--e: check-->

    </div>
</template>
<script>
import ProductCheck from './check'
export default ProductCheck
</script>
<style lang="less">
@import "check";
</style>
