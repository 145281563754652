/**
 * 产品检查
 */
import Vue from 'vue'
import { RadioGroup,Radio } from 'iview';
Vue.component('Radio-group', RadioGroup);
Vue.component('Radio', Radio);
import Header from '@/components/Header/header.vue'
import ProductType from '@/components/ProductType/productType.vue'
const ProductCheck = {
    name:'ProductInfo',
    components:{
        Header,
        ProductType
    },
    // computed: {
    //     ...mapState({
    //         userName: state => state.userCenter.userName,  //获取状态管理用户名称
    //     }),
    // },
    data() {
        return {
            detailsVisible:false, //默认展示
        }
    },
    created() {
    },
    methods: {
        /**
         * 展开更多信息
         */
        onDetailsMore() {
            this.detailsVisible?this.detailsVisible = false:this.detailsVisible = true
        },
        onSelectType(){

        },
    },
}
export default ProductCheck
